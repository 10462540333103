export const getCardClassName = (index) => {
   switch (index) {
      case 0:
         return "first";
      case 1:
         return "second";
      case 2:
         return "third";
      case 3:
         return "fourth";
      case 4:
         return "fifth";
      case 5:
         return "sixth";
      case 6:
         return "seventh";

      default:
         return "";
   }
};
