const mode = ["local", "development", "production"][2];
let apiBase;
switch (mode) {
  case "local":
    apiBase = "http://localhost:8002/api";
    break;
  case "development":
    apiBase = "https://socal.eachbase.com/api";
    break;
  case "production":
    apiBase = "https://socalairsystems.com/api";
    break;
  default:
    break;
}

export const API_BASE = apiBase;