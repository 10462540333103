import AmericanStandard from "./American Standard.svg";
import Carrier from "./Carrier.png";
import Goodman from "./Goodman.png";
import Lennox from "./Lennox.png";
import Rheem from "./Rheem.png";
import Trane from "./Trane.png";
import Yourk from "./Yourk.png";

export const PartnersLogos = {
   AmericanStandard,
   Carrier,
   Goodman,
   Lennox,
   Rheem,
   Trane,
   Yourk,
};
