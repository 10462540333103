import GoogleMapReact from "google-map-react";
import { Images } from "assets";
import { mapUi } from "../styles";

const _GOOGLE_MAP_REACT_API_KEY = {
   key: "AIzaSyBrhWs8H36A3m5NzHewZ9wTZrD2b_aoO-8",
};
const _COMPANY_LOCATION = { lat: 34.02635910147282, lng: -118.45729272891555 };
const _CUSTOM_MAP_OPTIONS = {
   fullscreenControl: false,
   styles: mapUi,
};

const CustomMarker = ({ children }) => <>{children}</>;

export const Map = ( ) =>{
    return(
        <div>
            <section className="connect-map">
            <GoogleMapReact
                        bootstrapURLKeys={_GOOGLE_MAP_REACT_API_KEY}
                        defaultZoom={12}
                        defaultCenter={_COMPANY_LOCATION}
                        options={_CUSTOM_MAP_OPTIONS}
                     >
                        <CustomMarker
                           lat={_COMPANY_LOCATION.lat}
                           lng={_COMPANY_LOCATION.lng}
                        >
                           <div className="marker-pin">
                              <img src={Images.locationIcon} alt="" />
                           </div>
                        </CustomMarker>
                     </GoogleMapReact>
            </section>
        </div>
    )
}